/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto, "Helvetica Neue", sans-serif; 
  background-color: #E4E4E4;}


@import '~primeng/resources/primeng.min.css';
@import '~primeicons/primeicons.css';
@import '~primeflex/primeflex.css';

@import '~primeng/resources/themes/nova/theme.css';

/* Official STM colors */
:root {
  --stm-blue: #001489;
  --stm-red: #DA291C;
}
/*
preferred-font, header { font-family: "Arial Narrow", Arial, sans-serif; font-size: 24px;
  font-style: normal; font-variant: normal; font-weight: 700;
  line-height: 26.4px; } h3 { font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 14px; font-style: normal; font-variant: normal; font-weight: 700;
  line-height: 15.4px; } p { font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 14px; font-style: normal; font-variant: normal; font-weight: 400;
  line-height: 20px; } blockquote { font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 21px; font-style: normal; font-variant: normal; font-weight: 400;
  line-height: 30px; } pre { font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 13px; font-style: normal; font-variant: normal; font-weight: 400; line-height: 18.5714px; }
*/
header, blockquote { font-family: "Arial Narrow", Arial, sans-serif;
  font-size: 14px; font-style: normal; font-variant: normal; font-weight: 700;
  line-height: 15.4px; }

body .ui-table .ui-table-thead>tr>th {
  border-top: 0px;
}

a {
  text-decoration: none;
}

.directory-header{
  background-color: var(--stm-blue);
  color: white;
}

tr > th{
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: var(--stm-blue) !important; 
  color: white !important;
}

@media screen and (max-width: 576px) {
  .sm-screen{
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .md-screen{
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .lg-screen{
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .xl-screen{
    display: none !important;
  }
}